<template>
<h1 class="text-center">Physics2DPlugin</h1>
</template>

<script>
export default {
  name: "Physics2DPlugin"
}
</script>

<style scoped>

</style>